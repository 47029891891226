<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Reactions of Copper - Pre-Lab Questions</h2>

      <p class="mb-n3">
        a) At the end of the experiment, a student ended up with a yield of 150%. What is/are the
        most likely explanation(s)?
      </p>

      <v-radio-group v-model="inputs.yieldOver100" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsYieldOver100"
          :key="'pt-1-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        b) Assume that you started the experiment with
        <number-value :value="startingMass" unit="\text{g}" />
        of copper. After going through the full experimental procedure, you recover
        <number-value :value="recoveredMass" unit="\text{g.}" />
        What are the theoretical yield (in grams) and the percent yield for the process?
      </p>

      <calculation-input
        v-model="inputs.theoYield"
        class="mb-2"
        prepend-text="$\text{Theoretical Yield:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.pctYield"
        class="mb-5"
        prepend-text="$\text{Percent Yield:}$"
        append-text="$\%$"
        :disabled="!allowEditing"
      />

      <p class="mb-n3">
        c) In this experiment, what types of reactions are you performing? Choose the best answer
        from the options below.
      </p>

      <v-radio-group v-model="inputs.reactionTypes" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsReactionTypes"
          :key="'pt-3-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        d) The process in which you pour off a solvent leaving behind a precipitate is known as
        what?
      </p>

      <v-radio-group
        v-model="inputs.leavingPrecipBehind"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsLeavingPrecipBehind"
          :key="'pt-4-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">e) What is the maximum correct percent yield in any reaction?</p>

      <v-radio-group v-model="inputs.maxYieldPossible" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsMaxYieldPossible"
          :key="'pt-5-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        f) What does it mean, in terms of temperature, if a reaction is exothermic?
      </p>

      <v-radio-group v-model="inputs.exothermicMeaning" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsExothermicMeaning"
          :key="'pt-6-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';

export default {
  name: 'OleMissPrelab8MC',
  components: {NumberValue, StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        yieldOver100: null,
        theoYield: null,
        pctYield: null,
        reactionTypes: null,
        leavingPrecipBehind: null,
        maxYieldPossible: null,
        exothermicMeaning: null,
      },
      optionsYieldOver100: [
        {text: 'Having boiling chips', value: 'boilingChips'},
        {text: 'Adding excess aluminum foil', value: 'excessFoil'},
        {text: 'All other options', value: 'allOthers'},
        {text: 'Improper drying -- too much water left in sample at the end', value: 'wet'},
      ],
      optionsReactionTypes: [
        {text: 'Redox and metathesis', value: 'redoxMetathesis'},
        {text: 'Ptrometric', value: 'ptrometric'},
        {text: 'Metal anomaly', value: 'metalAnomaly'},
        {text: 'Pyrolysis', value: 'pyrolysis'},
      ],
      optionsLeavingPrecipBehind: [
        {text: 'Filtration', value: 'filtration'},
        {text: 'Decantation', value: 'decantation'},
        {text: 'Centrifugation', value: 'centrifugation'},
        {text: 'Distillation', value: 'distillation'},
      ],
      optionsMaxYieldPossible: [
        {text: '$99.9\\%$', value: '99.9'},
        {text: '$99.99\\%$', value: '99.99'},
        {text: '$150\\%$', value: '150'},
        {text: '$100\\%$', value: '100'},
      ],
      optionsExothermicMeaning: [
        {text: 'Reaction releases heat and feels hot to touch', value: 'hot'},
        {text: 'Reaction vessel feels room temperature', value: 'room'},
        {text: 'Reaction absorbs heat and feels cold to touch', value: 'cold'},
        {text: 'Reaction is explosive', value: 'explosive'},
      ],
    };
  },
  computed: {
    startingMass() {
      return this.taskState.getValueBySymbol('startingMass').content;
    },
    recoveredMass() {
      return this.taskState.getValueBySymbol('recoveredMass').content;
    },
  },
};
</script>
<style scoped></style>
